import { h, VNode } from "preact";
import { useContext } from "preact/hooks";
import { Gift } from "../../../assets/svg/gift";
import { StarOutlineSmall } from "../../../assets/svg/star-outline-small";
import { StarOutlineXS } from "../../../assets/svg/star-outline-xs";
import { StarSmall } from "../../../assets/svg/star-small";
import { StarXS } from "../../../assets/svg/star-xs";
import { GlobalState } from "../../state";
import styles from "../styles.module.scss";

export function ReviewsList(): VNode {
  const {
    config,
    canWriteReview,
    couponSettings,
    customer,
    setView,
    isReviewsLoading,
    overallRating,
    reviews,
    totalNumberOfReviews,
    canLoadMore,
    fetchMoreReviews,
  } = useContext(GlobalState);

  const rewardMessage = () => {
    switch (couponSettings?.idRewardTypes) {
      case 1:
        return `You'll get ${couponSettings.discountAmount} SAR discount on your review`;
      case 2:
        return `You'll get ${couponSettings.discountAmount}% discount on your review`;
      case 3:
        return `You'll get a Free Shipping on your review`;

      default:
        return `You'll get 20% discount on your review`;
    }
  };

  return (
    <div className={styles.reviewsPage}>
      <div className={styles.rating}>
        <div className={styles.rating_total}>{overallRating}</div>
        <div className={styles.rating_stars}>
          <div className={styles.rating_stars_star}>
            {Array.from({ length: 5 }).map((_, idx) =>
              idx < overallRating ? <StarSmall /> : <StarOutlineSmall />
            )}
          </div>
          <div
            className={styles.rating_stars_text}
          >{`Based on ${totalNumberOfReviews} reviews`}</div>
        </div>
      </div>

      <div className={styles.sector}></div>

      {canWriteReview && (
        <div
          style={{
            border: `1px solid ${
              config?.pluginTheme?.primaryColor ?? "#FF6645"
            }`,
          }}
          className={styles.write}
        >
          <div className={styles.write_text}>
            {couponSettings?.isCouponEnabled ? (
              <div className={styles.write_text_reward}>
                <Gift
                  size="small"
                  color={config?.pluginTheme?.primaryColor ?? "#FF6645"}
                />
                <p
                  style={{
                    color: config?.pluginTheme?.primaryColor ?? "#FF6645",
                    marginTop: "10px !important",
                  }}
                >
                  {rewardMessage()}
                </p>
              </div>
            ) : (
              <div className={styles.write_text_normal}>
                <p style={{ marginBottom: "9px !important" }}>
                  <span>Hello </span>
                  <span
                    style={{
                      color: config?.pluginTheme?.primaryColor ?? "#FF6645",
                    }}
                  >
                    {customer.customer?.name},
                  </span>
                </p>
                <p style={{ marginBottom: "16px !important" }}>
                  What do you think of your last order?
                </p>
              </div>
            )}
          </div>
          <button
            onClick={() => setView("form")}
            style={{
              backgroundColor: config?.pluginTheme?.primaryColor ?? "#FF6645",
            }}
            className={styles.write_button}
          >
            {config?.pluginTheme?.buttonText}
          </button>
        </div>
      )}

      <div className={styles.reviews}>
        {reviews.length > 0 && <p className={styles.reviews_title}>Reviews</p>}

        <div className={styles.reviews_list}>
          {reviews.map((review) => (
            <div
              key={review?.idReviewsRequest}
              className={styles.reviews_list_review}
            >
              <div
                style={{
                  backgroundColor:
                    config?.pluginTheme?.primaryColor ?? "#FF6645",
                }}
                className={styles.reviews_list_review_avatar}
              >
                <p>{review?.name[0]}</p>
              </div>
              <div className={styles.reviews_list_review_body}>
                <p className={styles.reviews_list_review_body_name}>
                  {review?.name}
                </p>
                <div className={styles.reviews_list_review_body_rate}>
                  {Array.from({ length: 5 }).map((_, idx) =>
                    idx < review?.rate ? <StarXS /> : <StarOutlineXS />
                  )}
                </div>
                <p className={styles.reviews_list_review_body_comment}>
                  {review?.message}
                </p>
              </div>
              <div className={styles.reviews_list_review_date}>
                {new Date(review?.orderDate).toLocaleDateString("en-US")}
              </div>
            </div>
          ))}

          {canLoadMore && (
            <div className={styles.addMore}>
              <button
                onClick={() => fetchMoreReviews()}
                style={{
                  color: config?.pluginTheme?.primaryColor ?? "#FF6645",
                  border: `1px solid ${
                    config?.pluginTheme?.primaryColor ?? "#FF6645"
                  }`,
                }}
                disabled={isReviewsLoading}
                className={styles.addMore_button}
              >
                {isReviewsLoading ? "Loading..." : "Load More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
