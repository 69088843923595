import { h } from "preact";

export const Back = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="22"
      viewBox="0 0 24 22"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12054"
            data-name="Rectangle 12054"
            width="22"
            height="24"
            transform="translate(24) rotate(90)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="down_3" data-name="down 3">
        <path
          id="Path_60459"
          data-name="Path 60459"
          d="M17.929,21.713a.987.987,0,0,0,.045-1.48L8.431,11l9.542-9.232a.986.986,0,0,0-.045-1.48,1.218,1.218,0,0,0-1.616.04L6.027,10.28a.985.985,0,0,0,0,1.44l10.286,9.953A1.217,1.217,0,0,0,17.929,21.713Z"
          transform="translate(0 0)"
          fill="#292222"
        />
      </g>
    </svg>
  );
};
