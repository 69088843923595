import { h } from "preact";

interface IReviewsIcon4 {
  color?: string;
}

export const ReviewsIcon4 = ({ color = "#ff6645" }: IReviewsIcon4) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 28.125 28.125"
    >
      <g id="Group" transform="translate(-0.938 -0.938)">
        <path
          id="Path_60385"
          data-name="Path 60385"
          d="M25.781,29.063H4.219A3.285,3.285,0,0,1,.938,25.781V4.219A3.285,3.285,0,0,1,4.219.938H25.781a3.285,3.285,0,0,1,3.281,3.281V25.781a3.285,3.285,0,0,1-3.281,3.281ZM4.219,1.875A2.347,2.347,0,0,0,1.875,4.219V25.781a2.347,2.347,0,0,0,2.344,2.344H25.781a2.347,2.347,0,0,0,2.344-2.344V4.219a2.347,2.347,0,0,0-2.344-2.344Z"
          fill={color}
        />
        <path
          id="Path_60386"
          data-name="Path 60386"
          d="M7.031,27.188H4.219a1.406,1.406,0,0,1-1.406-1.406V22.969H3.75v2.812a.469.469,0,0,0,.469.469H7.031Z"
          fill={color}
        />
        <path
          id="Path_60387"
          data-name="Path 60387"
          d="M27.188,6.563H26.25V4.219a.469.469,0,0,0-.469-.469H22.969V2.813h2.812a1.406,1.406,0,0,1,1.406,1.406Z"
          fill={color}
        />
        <path
          id="Path_60388"
          data-name="Path 60388"
          d="M7.969,26.25h.938v.938H7.969Z"
          fill={color}
        />
        <path
          id="Path_60389"
          data-name="Path 60389"
          d="M21.094,2.813h.937V3.75h-.937Z"
          fill={color}
        />
        <path
          id="Path_60390"
          data-name="Path 60390"
          d="M18.566,21.791a1.87,1.87,0,0,1-1.1-.365l-1.913-1.39a.934.934,0,0,0-1.1,0l-1.913,1.39a1.875,1.875,0,0,1-2.886-2.1l.731-2.25a.935.935,0,0,0-.34-1.048l-1.913-1.39a1.875,1.875,0,0,1,1.1-3.391h2.365a.938.938,0,0,0,.891-.648l.731-2.25a1.875,1.875,0,0,1,3.566,0l.731,2.25a.938.938,0,0,0,.893.648h2.365a1.875,1.875,0,0,1,1.1,3.392l-1.913,1.389a.935.935,0,0,0-.34,1.048l.731,2.25a1.848,1.848,0,0,1-.681,2.1,1.875,1.875,0,0,1-1.1.365ZM15,18.918a1.872,1.872,0,0,1,1.1.359l1.913,1.39a.938.938,0,0,0,1.442-1.048l-.731-2.25a1.875,1.875,0,0,1,.679-2.1l1.913-1.39a.937.937,0,0,0-.549-1.7H18.406a1.87,1.87,0,0,1-1.781-1.3l-.731-2.25a.938.938,0,0,0-1.781,0l-.731,2.25a1.87,1.87,0,0,1-1.788,1.3H9.229a.937.937,0,0,0-.551,1.7l1.915,1.39a1.875,1.875,0,0,1,.681,2.1l-.731,2.25a.938.938,0,0,0,1.442,1.048l1.913-1.39a1.872,1.872,0,0,1,1.1-.36Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
