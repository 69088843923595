import { h, VNode } from "preact";
import { useContext, useState } from "preact/hooks";
import { StarLarg } from "../../../assets/svg/star-larg";
import { StarOutlineLarg } from "../../../assets/svg/star-outline-larg";
import { StarOutlineSmall } from "../../../assets/svg/star-outline-small";
import { StarSmall } from "../../../assets/svg/star-small";
import { witeAReview } from "../../services";
import { GlobalState } from "../../state";
import styles from "../styles.module.scss";

export function ReviewsForm(): VNode {
  const {
    storeEnv,
    overallRating,
    totalNumberOfReviews,
    setView,
    config,
    customer,
    couponSettings,
    pendingRequest,
    setCoupon,
    revalidate,
  } = useContext(GlobalState);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [stars, setStars] = useState([false, false, false, false, false]);

  return (
    <div>
      <div className={styles.rating}>
        <div className={styles.rating_total}>{overallRating}</div>
        <div className={styles.rating_stars}>
          <div className={styles.rating_stars_star}>
            {Array.from({ length: 5 }).map((_, idx) =>
              idx < overallRating ? <StarSmall /> : <StarOutlineSmall />
            )}
          </div>
          <div
            className={styles.rating_stars_text}
          >{`Based on ${totalNumberOfReviews} reviews`}</div>
        </div>
      </div>
      <div className={styles.sector}></div>

      <div className={styles.rate}>
        <div className={styles.rate_text}>Rate Us</div>
        <div className={styles.rate_stars}>
          {stars.map((hover, idx) => (
            <Star
              onClick={() => setRate(idx + 1)}
              solid={idx < rate}
              hover={hover}
              setHover={(active) =>
                setStars((prevStars) =>
                  prevStars.map((star, starIdx) =>
                    starIdx <= idx && active ? true : false
                  )
                )
              }
            />
          ))}
        </div>
      </div>

      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();

          try {
            setIsLoading(true);
            const { data } = await witeAReview(
              storeEnv.storeDomain,
              customer.authorization,
              {
                idReviewsRequest: pendingRequest?.idReviewsRequest,
                title,
                message: comment,
                rate,
                idVisit: pendingRequest?.idVisit,
              }
            );
            revalidate();

            if (couponSettings?.isCouponEnabled) {
              setCoupon(data?.data);
              setView("success");
            } else {
              setView("reviews");
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <p className={styles.form_label}>Title of review</p>
        <input
          required
          className={`${styles.form_input} ${styles.form_input_title}`}
          type="text"
          value={title}
          onInput={(e) => setTitle((e.target as HTMLInputElement).value)}
        />
        <p className={styles.form_label}>Description</p>
        <textarea
          required
          className={`${styles.form_input} ${styles.form_input_textarea}`}
          type="text"
          value={comment}
          onInput={(e) => setComment((e.target as HTMLInputElement).value)}
        />
        <div className={styles.form_submitButtons}>
          <button
            onClick={() => setView("reviews")}
            className={styles.form_submitButtons_cancel}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            style={{
              backgroundColor: config?.pluginTheme?.primaryColor ?? "#FF6645",
            }}
            type="submit"
            className={styles.form_submitButtons_submit}
          >
            Submit Review
          </button>
        </div>
      </form>
    </div>
  );
}

const Star = ({
  onClick,
  solid,
  hover,
  setHover,
}: {
  onClick: () => any;
  solid: boolean;
  hover: boolean;
  setHover: (active: boolean) => any;
}) => {
  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 5.5px",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      {hover ? <StarLarg /> : solid ? <StarLarg /> : <StarOutlineLarg />}
    </div>
  );
};
