import { h, VNode } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Beacon } from "./beacon";
import { ReviewsButton } from "./components/ReviewsButton";
import { getCustomer, getPendingReviews, getReviews, init } from "./services";
import { GlobalState } from "./state";
import "./styles/index.scss";
import { IConfig, IReviewsProps } from "./types";
import styles from "./beacon/styles.module.scss";
import { useBeaconPosition } from "./beacon/useBeaconPosition";

console.log("bonat reviews ver.0.0.3");

declare global {
  interface Window {
    customer?: {
      created_at: string;
      email: string;
      id: string;
      is_newsletter_subscriber: boolean;
      mobile: string;
      name: string;
      updated_at: string;
      verified: number;
    };
  }
}

const defaultProps: IReviewsProps = {
  customerEmail: "ezzat@bonat.io",
  customerId: "294910670",
  customerMobile: "201552709186",
  customerName: "mahmoud ezzat",
  dataWidgetHost: "habitat",
  storeDomain: "https://salla.sa/dev-1ds6y8gtqdqjttc7",
  storeEmail: "",
  storeId: "820450282",
  storeUsername: "dev-1ds6y8gtqdqjttc7",
  userEmail: "m.hussein@bonat.io",
  userId: "1608701973",
  userPhone: "+966500000000",
  platform: "salla",
};

export function Reviews(props: IReviewsProps): VNode {
  const [isBeaconHidden, setIsBeaconHidden] = useState(true);
  const [config, setConfig] = useState<IConfig>(null);
  const [customer, setCustomer] = useState(null);
  const [couponSettings, setCouponSettings] = useState(null);
  const [canWriteReview, setCanWriteReview] = useState(false);
  const [view, setView] = useState<"reviews" | "form" | "success">("reviews");
  const [totalPages, setTotalPages] = useState(1);
  const [totalNumberOfReviews, setTotalNumberOfReviews] = useState(0);
  const [isReviewsLoading, setIsReviewsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [overallRating, setOverallRating] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [pendingRequest, setPendingRequest] = useState(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const { beaconPosition } = useBeaconPosition(
    config?.pluginTheme?.tabPosition || "left"
  );

  const storeProps: IReviewsProps = props.storeDomain
    ? props.platform === "salla"
      ? props
      : {
          customerEmail: window.customer?.email || "",
          customerId: window.customer?.id || "",
          customerMobile: window.customer?.mobile || "",
          customerName: window.customer?.name || "",
          dataWidgetHost: "",
          platform: "zid",
          storeDomain: "",
          storeEmail: "",
          storeId: "",
          storeUsername: "",
          userEmail: "",
          userId: "",
          userPhone: "",
        }
    : defaultProps;

  const fetchReviews = async (selectedPage: number) => {
    try {
      setIsReviewsLoading(true);
      const { data } = await getReviews(storeProps.storeDomain, selectedPage);

      setOverallRating(Number(data?.data?.overallRate));
      setReviews([...data?.data?.reviews]);
      setTotalPages(data.totalPages);
      setTotalNumberOfReviews(data?.data?.totalNumberOfReviews);

      if (data.totalPages > page) {
        setCanLoadMore(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsReviewsLoading(false);
    }
  };

  const getConfig = async () => {
    try {
      const { data: storeConfig } = await init(storeProps.storeDomain);
      setConfig(storeConfig?.data?.[0] ?? null);

      fetchReviews(page);

      if (storeProps.customerId) {
        const { data: bonatCusomer } = await getCustomer(
          storeProps.storeDomain,
          storeProps.customerId
        );

        setCustomer(bonatCusomer?.data?.[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPendingReviews = async () => {
    try {
      const { data } = await getPendingReviews(
        storeProps.storeDomain,
        customer.authorization
      );

      setCouponSettings(data?.data?.couponSettings);
      setPendingRequest(data?.data?.result?.[0]);

      setCanWriteReview(data?.data?.result?.[0]?.idVisit);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (customer?.authorization) {
      fetchPendingReviews();
    }
  }, [customer?.authorization]);

  useEffect(() => {
    getConfig();
  }, []);

  const fetchMoreReviews = async () => {
    try {
      setIsReviewsLoading(true);
      const { data } = await getReviews(storeProps.storeDomain, page + 1);

      setReviews((prevReviews) => [...prevReviews, ...data?.data?.reviews]);

      setPage(page + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsReviewsLoading(false);
    }
  };

  useEffect(() => {
    if (totalPages > page) {
      setCanLoadMore(true);
    }
  }, [page, totalPages]);

  const revalidate = () => {
    fetchPendingReviews();
    fetchReviews(page);
  };

  return (
    <GlobalState.Provider
      value={{
        storeEnv: storeProps,
        config,
        view,
        customer,
        canWriteReview,
        couponSettings,
        setView,
        totalPages,
        totalNumberOfReviews,
        isReviewsLoading,
        page,
        reviews,
        overallRating,
        coupon,
        setCoupon,
        pendingRequest,
        canLoadMore,
        fetchMoreReviews,
        revalidate,
      }}
    >
      <div
        className="reviews"
        style={{
          ...(config?.pluginTheme?.tabPosition === "left"
            ? { left: "-15px" }
            : { right: "-15px" }),
          ...(!config?.pluginTheme?.isEnabled && { display: "none" }),
        }}
      >
        <div
          className={styles.beacon}
          style={{
            ...beaconPosition(),
            ...(!config?.pluginTheme?.isEnabled && { display: "none" }),
          }}
        >
          <ReviewsButton
            onClick={() => setIsBeaconHidden((prevValue) => !prevValue)}
            icon={config?.pluginTheme?.tabIcon || "icon1"}
            text={config?.pluginTheme?.tabText || "Reviews"}
            color={config?.pluginTheme?.primaryColor || "#FF6645"}
            position={config?.pluginTheme?.tabPosition || "left"}
          />

          <Beacon
            hidden={isBeaconHidden}
            position={config?.pluginTheme?.tabPosition || "left"}
          />
        </div>
      </div>
    </GlobalState.Provider>
  );
}
