import { Fragment, h, VNode } from "preact";
import { useContext } from "preact/hooks";
import { BonatLogo } from "../../assets/svg/bonat-logo";
import { NotchIcon } from "../../assets/svg/notch";
import { GlobalState } from "../state";
import styles from "./styles.module.scss";
import { useBeaconPosition } from "./useBeaconPosition";
import { ReviewsForm } from "./views/reviews-form";
import { ReviewsList } from "./views/reviews-list";
import { ReviewsSuccess } from "./views/reviews-success";

interface IBeacon {
  hidden: boolean;
  position: "left" | "right";
}

export function Beacon({ position, hidden }: IBeacon): VNode {
  const { bodyPosition, notchPosition } = useBeaconPosition(position);

  const { view } = useContext(GlobalState);

  const currentView = () => {
    switch (view) {
      case "reviews":
        return <ReviewsList />;
      case "form":
        return <ReviewsForm />;
      case "success":
        return <ReviewsSuccess />;
      default:
        return <ReviewsList />;
    }
  };

  return (
    <Fragment>
      <NotchIcon
        className={styles.beacon_notch}
        style={{
          ...notchPosition(),
          ...(position === "right" && { transform: "rotate(180deg)" }),
          ...(hidden && { display: "none" }),
        }}
      />
      <div
        style={{ ...bodyPosition(), ...(hidden && { display: "none" }) }}
        className={styles.beacon_body}
      >
        <div className={styles.beacon_body_main}>{currentView()}</div>
        <div className={styles.beacon_body_footer}>
          <BonatLogo />
          <p>Powered by</p>
        </div>
      </div>
    </Fragment>
  );
}
