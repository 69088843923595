import habitat from "preact-habitat";

import { Reviews } from "./widget";

const _habitat = habitat(Reviews);


_habitat.render({
  selector: '[data-widget-host="habitat"]',
});
