import { h } from "preact";

interface INotchIcon {
  flip?: boolean;
  style?: h.JSX.CSSProperties;
  className?: string;
}

export const NotchIcon = ({ style = {}, className = "" }: INotchIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.791"
      height="36"
      viewBox="0 0 14.791 36"
      style={style}
      className={className}
    >
      <path
        id="Union_12"
        data-name="Union 12"
        d="M18,0H36S29.118,1.71,24.618,5.408A24.967,24.967,0,0,0,18,14.791ZM11.382,5.408C6.882,1.71,0,0,0,0H18V14.791A24.945,24.945,0,0,0,11.382,5.408Z"
        transform="translate(14.791) rotate(90)"
        fill="#fff"
      />
    </svg>
  );
};
