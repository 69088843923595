import { h, VNode } from "preact";
import { useContext, useState } from "preact/hooks";
import { Back } from "../../../assets/svg/back";
import { Gift } from "../../../assets/svg/gift";
import { GlobalState } from "../../state";
import styles from "../styles.module.scss";

export function ReviewsSuccess(): VNode {
  const { view, config, couponSettings, coupon, setView } =
    useContext(GlobalState);
  const [isCopying, setIsCopying] = useState(false);

  const rewardMessage = () => {
    switch (couponSettings?.idRewardTypes) {
      case 1:
        return `You'll get ${couponSettings.discountAmount} SAR discount on your review`;
      case 2:
        return `You'll get ${couponSettings.discountAmount}% discount on your review`;
      case 3:
        return `You'll get a Free Shipping on your review`;

      default:
        return `You'll get 20% discount on your review`;
    }
  };

  return (
    <div className={styles.success}>
      <Gift
        size="larg"
        color={config?.pluginTheme?.primaryColor ?? "#FF6645"}
      />
      <p
        className={styles.success_title}
        style={{
          color: config?.pluginTheme?.primaryColor ?? "#FF6645",
        }}
      >
        Congratulations
      </p>
      <p className={styles.success_message}>{rewardMessage()}</p>
      <p className={styles.success_code}>Code:</p>

      <div
        className={styles.success_copy}
        style={{
          border: `1px solid ${config?.pluginTheme?.primaryColor ?? "#FF6645"}`,
        }}
      >
        <p style={{ color: "#292222", fontWeight: 500 }}>{coupon}</p>
        <p
          onClick={() => {
            navigator.clipboard.writeText(coupon);
            setIsCopying(true);
            setTimeout(() => {
              setIsCopying(false);
            }, 2000);
          }}
          style={{
            color: config?.pluginTheme?.primaryColor ?? "#FF6645",
            cursor: "pointer",
          }}
        >
          {isCopying ? "copied" : "Copy"}
        </p>
      </div>

      <div
        onClick={() => setView("reviews")}
        style={{
          direction: "ltr",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          cursor: "pointer",
        }}
      >
        <Back />
        <p
          style={{
            fontSize: "13px",
            fontWeight: 500,
            color: "#292222",
          }}
        >
          Back
        </p>
      </div>
    </div>
  );
}
