import { h } from "preact";
import { ReviewsIcon1 } from "../../../assets/svg/icon1";
import { ReviewsIcon2 } from "../../../assets/svg/icon2";
import { ReviewsIcon3 } from "../../../assets/svg/icon3";
import { ReviewsIcon4 } from "../../../assets/svg/icon4";
import { ReviewsIcon5 } from "../../../assets/svg/icon5";
import styles from "./styles.module.scss";

interface IReviewsButton {
  text?: string;
  icon?: "icon1" | "icon2" | "icon3" | "icon4" | "icon5";
  color?: string;
  onClick: h.JSX.MouseEventHandler<HTMLButtonElement>;
  position: "left" | "right";
}

export function ReviewsButton({
  text = "Reviews",
  icon = "icon1",
  color = "#ff6645",
  onClick,
  position,
}: IReviewsButton) {
  const reviewIcon = () => {
    switch (icon) {
      case "icon1":
        return <ReviewsIcon1 color="#fff" />;
      case "icon2":
        return <ReviewsIcon2 color="#fff" />;
      case "icon3":
        return <ReviewsIcon3 color="#fff" />;
      case "icon4":
        return <ReviewsIcon4 color="#fff" />;
      case "icon5":
        return <ReviewsIcon5 color="#fff" />;
      default:
        return <ReviewsIcon1 color="#fff" />;
    }
  };

  const buttonPosition = () => {
    if (position === "left") {
      return {
        left: "1px",
        transformOrigin: "left",
        right: "unset",
        transform: "rotate(270deg) translateY(100%)",
      };
    } else {
      return {
        right: "1px",
        left: "unset",
        transformOrigin: "right",
        transform: "rotate(270deg) translateY(-100%) translateX(100%)",
      };
    }
  };

  return (
    <button
      onClick={onClick}
      style={{ ...buttonPosition(), backgroundColor: color }}
      className={styles.reviewsButton}
    >
      {reviewIcon()}
      <span className={styles.reviewsButton_text}>{text}</span>
    </button>
  );
}
