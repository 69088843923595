export const useBeaconPosition = (position: "left" | "right") => {
  const beaconPosition = () => {
    if (position === "left") {
      return {
        left: "0px",
        right: "unset",
        bottom: "4px",
      };
    } else {
      return {
        right: "0px",
        left: "unset",
        bottom: "4px",
      };
    }
  };

  const bodyPosition = () => {
    if (position === "left") {
      return {
        left: "67px",
        right: "unset",
        bottom: "-18px",
      };
    } else {
      return {
        right: "67px",
        left: "unset",
        bottom: "-18px",
      };
    }
  };

  const notchPosition = () => {
    if (position === "left") {
      return {
        left: "52px",
        right: "unset",
        bottom: "12px",
      };
    } else {
      return {
        right: "52px",
        left: "unset",
        bottom: "12px",
      };
    }
  };

  return { notchPosition, bodyPosition, beaconPosition };
};
