import { h } from "preact";

export const StarLarg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="33.097"
      height="33.097"
      viewBox="0 0 33.097 33.097"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12047"
            data-name="Rectangle 12047"
            width="33.097"
            height="33.097"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_58625" data-name="Group 58625">
        <path
          id="Path_60430"
          data-name="Path 60430"
          d="M33.01,12.049A1.756,1.756,0,0,0,31.5,10.84l-9.553-.867L18.166,1.131a1.759,1.759,0,0,0-3.235,0l-3.777,8.84L1.6,10.84a1.761,1.761,0,0,0-1,3.078L7.822,20.25,5.692,29.629a1.756,1.756,0,0,0,2.616,1.9l8.24-4.925,8.237,4.925a1.759,1.759,0,0,0,2.618-1.9L25.274,20.25l7.221-6.331a1.76,1.76,0,0,0,.516-1.87Z"
          fill="#ffc107"
        />
      </g>
    </svg>
  );
};
