import { h } from "preact";

export const StarSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16.167"
      height="16.167"
      viewBox="0 0 16.167 16.167"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12024"
            data-name="Rectangle 12024"
            width="16.167"
            height="16.167"
            transform="translate(80.834)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_58569"
        data-name="Group 58569"
        transform="translate(-80.834)"
      >
        <path
          id="Path_60400"
          data-name="Path 60400"
          d="M96.958,5.886a.858.858,0,0,0-.74-.591l-4.666-.424L89.707.553a.859.859,0,0,0-1.58,0L86.282,4.871l-4.667.424a.86.86,0,0,0-.488,1.5l3.527,3.093-1.04,4.581a.858.858,0,0,0,1.278.928L88.917,13,92.94,15.4a.859.859,0,0,0,1.279-.928l-1.04-4.581L96.706,6.8a.86.86,0,0,0,.252-.913Z"
          fill="#ffc107"
        />
      </g>
    </svg>
  );
};
