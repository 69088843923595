import { h } from "preact";

interface IReviewsIcon5 {
  color?: string;
}

export const ReviewsIcon5 = ({ color = "#ff6645" }: IReviewsIcon5) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12019"
            data-name="Rectangle 12019"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="rating4_1" data-name="rating4 1" clip-path="url(#clip-path)">
        <path
          id="Path_60391"
          data-name="Path 60391"
          d="M28.679,0H22.835a.44.44,0,0,0,0,.881h5.844a.441.441,0,0,1,.44.44v8.863a.441.441,0,0,1-.44.44H16.563v-.212a1.558,1.558,0,1,0-3.115,0v.212H8.963a.44.44,0,0,0,0,.881h4.485v7.76L12.4,18.092a3.409,3.409,0,0,0-2.542-1.139,1.014,1.014,0,0,0-.891,1.5l1.754,3.221a8.709,8.709,0,0,0,1.648,2.148L14.24,25.6v.943a1.008,1.008,0,0,0-.8.986v1.464A1.008,1.008,0,0,0,14.445,30h6.942a1.008,1.008,0,0,0,1.007-1.007V27.529a1.008,1.008,0,0,0-.772-.978v-.979a9.512,9.512,0,0,0,1.646-5.933V16.95a1.557,1.557,0,0,0-2.345-1.344,1.557,1.557,0,0,0-2.235-.768,1.558,1.558,0,0,0-2.124-.827V11.5H28.679A1.322,1.322,0,0,0,30,10.184V1.321A1.322,1.322,0,0,0,28.679,0ZM17.241,14.737a.678.678,0,0,1,.677.677v.768a.44.44,0,1,0,.881,0,.677.677,0,0,1,1.354,0v.768a.44.44,0,1,0,.881,0,.677.677,0,0,1,1.354,0v2.69c0,3.783-1.511,5.455-1.525,5.469a.44.44,0,0,0-.121.3v1.111H19.678a.44.44,0,0,0,0,.881h1.71a.126.126,0,0,1,.126.126v1.464a.126.126,0,0,1-.126.126H14.445a.126.126,0,0,1-.126-.126V27.529a.126.126,0,0,1,.126-.126h3.471a.44.44,0,0,0,0-.881h-2.8V25.412a.441.441,0,0,0-.137-.319l-2.006-1.908A7.826,7.826,0,0,1,11.5,21.253L9.743,18.032c-.041-.111,0-.181.117-.2a2.528,2.528,0,0,1,1.884.844l1.8,2.015a.453.453,0,0,0,.786-.293V10.412a.677.677,0,1,1,1.354,0v5a.44.44,0,0,0,.881,0,.678.678,0,0,1,.677-.677Z"
          fill={color}
        />
        <path
          id="Path_60392"
          data-name="Path 60392"
          d="M7.2,10.624H1.321a.441.441,0,0,1-.44-.44V1.321a.441.441,0,0,1,.44-.44H21.074a.44.44,0,1,0,0-.881H1.321A1.322,1.322,0,0,0,0,1.321v8.862A1.323,1.323,0,0,0,1.321,11.5H7.2a.44.44,0,0,0,0-.881Z"
          fill={color}
        />
        <path
          id="Path_60393"
          data-name="Path 60393"
          d="M17.9,5.421a.942.942,0,0,0-.522-1.607L16.35,3.665a.062.062,0,0,1-.046-.034L15.845,2.7a.942.942,0,0,0-1.69,0l-.458.929a.062.062,0,0,1-.046.034l-1.025.149A.942.942,0,0,0,12.1,5.421l.742.723a.062.062,0,0,1,.018.055L12.687,7.22a.942.942,0,0,0,1.367.993l.917-.482a.061.061,0,0,1,.057,0l.917.482a.942.942,0,0,0,1.367-.993L17.138,6.2a.061.061,0,0,1,.018-.055Zm-1.357.093a.942.942,0,0,0-.271.834l.175,1.021a.062.062,0,0,1-.09.065l-.917-.482a.942.942,0,0,0-.877,0l-.917.482a.062.062,0,0,1-.089-.065l.175-1.021a.942.942,0,0,0-.271-.834l-.742-.723a.062.062,0,0,1,.034-.105l1.026-.149a.942.942,0,0,0,.709-.515l.459-.929a.062.062,0,0,1,.11,0l.458.929a.942.942,0,0,0,.71.516l1.025.149a.062.062,0,0,1,.034.105Z"
          fill={color}
        />
        <path
          id="Path_60394"
          data-name="Path 60394"
          d="M8.534,7.22,8.359,6.2a.061.061,0,0,1,.018-.055l.742-.723A.942.942,0,0,0,8.6,3.814L7.571,3.665a.062.062,0,0,1-.046-.034L7.066,2.7a.942.942,0,0,0-1.69,0l-.458.929a.062.062,0,0,1-.046.034l-1.025.149a.942.942,0,0,0-.522,1.607l.742.723a.061.061,0,0,1,.018.055L3.908,7.22a.942.942,0,0,0,1.367.993l.917-.482a.062.062,0,0,1,.057,0l.917.482A.942.942,0,0,0,8.534,7.22ZM7.762,5.514a.942.942,0,0,0-.271.834l.175,1.021a.062.062,0,0,1-.089.065l-.917-.482a.942.942,0,0,0-.877,0l-.917.482a.062.062,0,0,1-.089-.065l.175-1.021a.942.942,0,0,0-.271-.834l-.742-.723a.062.062,0,0,1,.034-.105L5,4.537a.942.942,0,0,0,.709-.515l.459-.929a.062.062,0,0,1,.111,0l.459.929a.942.942,0,0,0,.709.516l1.025.149a.062.062,0,0,1,.034.105Z"
          fill={color}
        />
        <path
          id="Path_60395"
          data-name="Path 60395"
          d="M26.677,5.421a.942.942,0,0,0-.522-1.607l-1.025-.149a.062.062,0,0,1-.047-.034L24.624,2.7a.942.942,0,0,0-1.69,0l-.458.929a.062.062,0,0,1-.046.034L21.4,3.814a.942.942,0,0,0-.522,1.607l.742.723a.062.062,0,0,1,.018.055L21.466,7.22a.942.942,0,0,0,1.367.993l.917-.482a.062.062,0,0,1,.057,0l.917.482a.942.942,0,0,0,1.367-.993L25.917,6.2a.062.062,0,0,1,.018-.055Zm-1.357.093a.942.942,0,0,0-.271.834l.175,1.021a.062.062,0,0,1-.09.065l-.917-.482a.942.942,0,0,0-.877,0l-.917.482a.062.062,0,0,1-.089-.065l.175-1.021a.942.942,0,0,0-.271-.834L21.5,4.791a.062.062,0,0,1,.034-.105l1.025-.149a.943.943,0,0,0,.709-.515l.458-.929a.062.062,0,0,1,.111,0l.458.929a.942.942,0,0,0,.71.516l1.025.149a.062.062,0,0,1,.034.105Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
