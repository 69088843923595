import { h } from "preact";

interface IReviewsIcon3 {
  color?: string;
}

export const ReviewsIcon3 = ({ color = "#ff6645" }: IReviewsIcon3) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12018"
            data-name="Rectangle 12018"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="rating2_1" data-name="rating2 1" clip-path="url(#clip-path)">
        <path
          id="Path_60384"
          data-name="Path 60384"
          d="M29.922,10.921a1.593,1.593,0,0,0-1.371-1.1L19.89,9.04,16.468,1.027a1.6,1.6,0,0,0-2.934,0L10.111,9.04,1.45,9.826a1.6,1.6,0,0,0-.906,2.79l6.546,5.74-1.93,8.5a1.594,1.594,0,0,0,2.373,1.724L15,24.116l7.466,4.465a1.6,1.6,0,0,0,2.374-1.724l-1.93-8.5,6.546-5.74a1.6,1.6,0,0,0,.465-1.695Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
