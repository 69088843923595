import { h } from "preact";

export const StarOutlineSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16.835"
      height="16.835"
      viewBox="0 0 16.835 16.835"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12046"
            data-name="Rectangle 12046"
            width="16.835"
            height="16.835"
            transform="translate(80.165)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_58620"
        data-name="Group 58620"
        transform="translate(-80.165)"
      >
        <path
          id="Path_60429"
          data-name="Path 60429"
          d="M92.517,15.62a1.222,1.222,0,0,1-.573-.142l-3.279-1.723a.175.175,0,0,0-.165,0l-3.278,1.723a1.23,1.23,0,0,1-1.785-1.3l.626-3.651a.178.178,0,0,0-.053-.158l-2.65-2.587a1.23,1.23,0,0,1,.684-2.1l3.663-.532a.177.177,0,0,0,.134-.1l1.638-3.322a1.229,1.229,0,0,1,2.206,0l1.639,3.321a.179.179,0,0,0,.134.1l3.666.532a1.231,1.231,0,0,1,.684,2.1l-2.653,2.585a.179.179,0,0,0-.053.158l.626,3.651a1.231,1.231,0,0,1-1.21,1.439Zm-3.935-2.939a1.223,1.223,0,0,1,.572.142l3.279,1.723a.169.169,0,0,0,.187-.013.171.171,0,0,0,.071-.174l-.627-3.651a1.233,1.233,0,0,1,.354-1.088l2.653-2.586a.178.178,0,0,0-.1-.3L91.307,6.2a1.228,1.228,0,0,1-.926-.673L88.74,2.2a.178.178,0,0,0-.319,0L86.783,5.524a1.228,1.228,0,0,1-.926.673l-3.665.534a.177.177,0,0,0-.1.3l2.653,2.586a1.233,1.233,0,0,1,.354,1.088l-.627,3.651a.178.178,0,0,0,.163.208.179.179,0,0,0,.1-.021l3.278-1.724a1.229,1.229,0,0,1,.572-.141Z"
          fill="#ffc107"
        />
      </g>
    </svg>
  );
};
