import { h } from "preact";

interface IReviewsIcon1 {
  color?: string;
}

export const ReviewsIcon1 = ({ color = "#ff6645" }: IReviewsIcon1) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12016"
            data-name="Rectangle 12016"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="rating5_1_1_1"
        data-name="rating5 1 (1) 1"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_60376"
          data-name="Path 60376"
          d="M26.767,0H3.232A3.236,3.236,0,0,0,0,3.232V23a3.236,3.236,0,0,0,3.232,3.233H10.87l3.508,3.508a.879.879,0,0,0,1.243,0l3.508-3.508h7.638A3.236,3.236,0,0,0,30,23V3.232A3.236,3.236,0,0,0,26.767,0Zm1.475,23a1.476,1.476,0,0,1-1.475,1.475h-8a.879.879,0,0,0-.622.257L15,27.878l-3.144-3.144a.879.879,0,0,0-.622-.257h-8A1.476,1.476,0,0,1,1.758,23V3.232A1.476,1.476,0,0,1,3.232,1.758H26.767a1.476,1.476,0,0,1,1.475,1.475Z"
          fill={color}
        />
        <path
          id="Path_60377"
          data-name="Path 60377"
          d="M22.55,10.11l-4.672-.679L15.788,5.2a.879.879,0,0,0-1.576,0l-2.09,4.234L7.45,10.11a.879.879,0,0,0-.487,1.5l3.381,3.3-.8,4.654a.879.879,0,0,0,1.275.927L15,18.288l4.18,2.2a.878.878,0,0,0,.409.1h0a.88.88,0,0,0,.855-1.075l-.79-4.606,3.381-3.3a.879.879,0,0,0-.487-1.5ZM18.1,13.969a.879.879,0,0,0-.253.778l.576,3.354-3.012-1.583a.879.879,0,0,0-.818,0L11.579,18.1l.575-3.354a.879.879,0,0,0-.253-.778L9.465,11.594l3.367-.489a.879.879,0,0,0,.662-.481L15,7.572l1.506,3.052a.879.879,0,0,0,.662.481l3.367.489Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
