import { h } from "preact";

export const Gift = ({
  color = "#ff6645",
  size = "larg",
}: {
  color: string;
  size: "small" | "larg";
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size === "small" ? "35.3" : "88.239"}
      height={size === "small" ? "33.78" : "84.449"}
      viewBox="0 0 88.239 84.449"
    >
      <g
        id="gift_2_1_"
        data-name="gift 2 (1)"
        transform="translate(-0.882 -2.776)"
      >
        <path
          id="Path_60447"
          data-name="Path 60447"
          d="M62.005,51.236h-46.4l40.18-25.547a1.055,1.055,0,0,0,.324-1.456l-3.981-6.261a6.378,6.378,0,0,0-8.795-1.958L37.68,19.609l.448-2.143a6.032,6.032,0,1,0-11.775-2.622L23.416,28.037,13.58,25.848a6.032,6.032,0,0,0-3.691,11.43L3.832,41.13a6.378,6.378,0,0,0-1.958,8.795l3.981,6.261a1.055,1.055,0,0,0,1.456.324l3.706-2.355v26.7a6.378,6.378,0,0,0,6.371,6.371h39.3a6.378,6.378,0,0,0,6.371-6.371V52.291a1.055,1.055,0,0,0-1.055-1.055ZM44.466,17.794a4.233,4.233,0,0,1,2.278-.667,4.3,4.3,0,0,1,.934.1,4.235,4.235,0,0,1,2.67,1.873l3.415,5.371L37.153,35.037l-5.7-8.967ZM28.412,15.3a3.922,3.922,0,0,1,7.654,1.718L35.2,21.163l-9.434,6.02ZM29.67,27.2l5.7,8.967-9.626,6.12-5.7-8.967ZM8.96,33.84a3.922,3.922,0,0,1,4.162-5.933l8.523,1.9-9.461,5.931-.767-.171A3.9,3.9,0,0,1,8.96,33.84ZM7.069,54.164,3.654,48.793a4.267,4.267,0,0,1,1.31-5.883l13.3-8.457,5.7,8.967ZM27.522,85.115H17.388a4.266,4.266,0,0,1-4.262-4.262V53.345h14.4Zm13.406,0h-11.3V53.345h11.3Zm20.023-4.262a4.266,4.266,0,0,1-4.261,4.262H43.037V53.345H60.951Z"
          fill={color}
        />
        <path
          id="Path_60448"
          data-name="Path 60448"
          d="M73.8,41.655l.5-2.942a1.055,1.055,0,0,0-1.53-1.112L70.131,38.99,67.49,37.6a1.055,1.055,0,0,0-1.53,1.112l.5,2.942-2.137,2.083a1.055,1.055,0,0,0,.584,1.8l2.953.429,1.321,2.676a1.055,1.055,0,0,0,1.892,0L72.4,45.966l2.953-.429a1.055,1.055,0,0,0,.584-1.8Zm-2.253,2.3a1.055,1.055,0,0,0-.794.577l-.62,1.257-.62-1.257a1.054,1.054,0,0,0-.794-.577l-1.388-.2,1-.979a1.055,1.055,0,0,0,.3-.934L68.4,40.462l1.241.652a1.053,1.053,0,0,0,.981,0l1.241-.652-.237,1.382a1.055,1.055,0,0,0,.3.934l1,.979Z"
          fill={color}
        />
        <path
          id="Path_60449"
          data-name="Path 60449"
          d="M85.929,27.123l-2.9-.7L81.965,23.63a1.055,1.055,0,0,0-1.883-.178l-1.566,2.541-2.981.15a1.055,1.055,0,0,0-.751,1.736l1.932,2.275-.778,2.881a1.055,1.055,0,0,0,1.419,1.251l2.76-1.135,2.5,1.631a1.055,1.055,0,0,0,1.628-.963l-.226-2.976,2.323-1.873a1.055,1.055,0,0,0-.413-1.846Zm-3.666,2.426a1.055,1.055,0,0,0-.39.9l.105,1.4L80.8,31.081a1.055,1.055,0,0,0-.977-.092l-1.3.533.366-1.354a1.055,1.055,0,0,0-.214-.958l-.908-1.068,1.4-.07a1.055,1.055,0,0,0,.845-.5l.736-1.193.5,1.31a1.054,1.054,0,0,0,.736.649l1.363.331Z"
          fill={color}
        />
        <path
          id="Path_60450"
          data-name="Path 60450"
          d="M70.418,9.978l-.778,2.881a1.055,1.055,0,0,0,1.419,1.251l2.76-1.135,2.5,1.631a1.055,1.055,0,0,0,1.628-.963l-.226-2.976,2.323-1.873a1.055,1.055,0,0,0-.413-1.846l-2.9-.7L75.667,3.454a1.055,1.055,0,0,0-1.883-.178l-1.566,2.54-2.981.15A1.055,1.055,0,0,0,68.485,7.7ZM72.877,7.9a1.055,1.055,0,0,0,.845-.5L74.458,6.2l.5,1.31a1.055,1.055,0,0,0,.736.649l1.362.331-1.091.88a1.055,1.055,0,0,0-.39.9l.105,1.4-1.174-.766a1.055,1.055,0,0,0-.977-.092l-1.3.533L72.6,9.993a1.055,1.055,0,0,0-.214-.958l-.908-1.069Z"
          fill={color}
        />
        <path
          id="Path_60451"
          data-name="Path 60451"
          d="M51.283,41.807A12.132,12.132,0,0,0,47.69,45.2a1.055,1.055,0,1,0,1.781,1.13c.158-.246,3.974-6.014,12.28-3.96a1.055,1.055,0,0,0,.507-2.048,13.783,13.783,0,0,0-10.975,1.487Z"
          fill={color}
        />
        <path
          id="Path_60452"
          data-name="Path 60452"
          d="M71.3,30.637a1.055,1.055,0,0,0,.809-1.948c-7.508-3.116-12.965-.783-16.221,1.724a19.772,19.772,0,0,0-5.25,6.2,1.055,1.055,0,1,0,1.889.938,17.786,17.786,0,0,1,4.648-5.462c4.094-3.151,8.846-3.638,14.126-1.447Z"
          fill={color}
        />
        <path
          id="Path_60453"
          data-name="Path 60453"
          d="M59.446,22.824a1.055,1.055,0,0,0,.679,1.328A15.844,15.844,0,0,0,64.6,24.8a11,11,0,0,0,5.628-1.4c2.391-1.417,3.924-3.83,4.559-7.172a1.055,1.055,0,1,0-2.072-.394,8.015,8.015,0,0,1-3.553,5.746c-3.5,2.083-8.309.592-8.385.568a1.055,1.055,0,0,0-1.327.679Z"
          fill={color}
        />
        <path
          id="Path_60454"
          data-name="Path 60454"
          d="M45.5,50.075a1.9,1.9,0,1,0-1.342-.557,1.9,1.9,0,0,0,1.342.557Zm0-2.109a.211.211,0,1,1-.148.062.209.209,0,0,1,.148-.062Z"
          fill={color}
        />
        <path
          id="Path_60455"
          data-name="Path 60455"
          d="M60.449,15.622a1.9,1.9,0,1,0-1.342-.557,1.9,1.9,0,0,0,1.342.557Zm0-2.109a.211.211,0,1,1-.148.062.21.21,0,0,1,.148-.062Z"
          fill={color}
        />
        <path
          id="Path_60456"
          data-name="Path 60456"
          d="M87.222,14.174a1.9,1.9,0,1,0,1.342.557,1.9,1.9,0,0,0-1.342-.557Zm0,2.109a.211.211,0,1,1,.148-.062.21.21,0,0,1-.148.062Z"
          fill={color}
        />
        <path
          id="Path_60457"
          data-name="Path 60457"
          d="M44.727,39.38a1.9,1.9,0,1,0-.556,1.342,1.9,1.9,0,0,0,.556-1.342Zm-1.9.211a.213.213,0,1,1,.148-.062.211.211,0,0,1-.148.063Z"
          fill={color}
        />
        <path
          id="Path_60458"
          data-name="Path 60458"
          d="M84.094,40.572a1.9,1.9,0,1,0,1.343.557,1.9,1.9,0,0,0-1.343-.557Zm0,2.109a.211.211,0,1,1,.148-.062.21.21,0,0,1-.148.062Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
