import { h } from "preact";

export const BonatLogo = () => {
  return (
    <svg
      id="bonat_logo"
      data-name="bonat logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60.31"
      height="17"
      viewBox="0 0 35.487 10.003"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_7412"
            data-name="Path 7412"
            d="M70.029,366.516a.7.7,0,1,0,.7-.7.7.7,0,0,0-.7.7"
            transform="translate(-70.029 -365.82)"
            fill="#ff6645"
            clip-rule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_852"
            data-name="Rectangle 852"
            width="38.175"
            height="40.551"
            fill="#ff6645"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <circle
            id="Ellipse_706"
            data-name="Ellipse 706"
            cx="0.696"
            cy="0.696"
            r="0.696"
            fill="#ff6645"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <path
            id="Path_7414"
            data-name="Path 7414"
            d="M70.029,144.793a2.848,2.848,0,1,1,4.719,2.148,2.848,2.848,0,0,1-1.87,5h-.483a.7.7,0,1,1,0-1.4h.483a1.448,1.448,0,1,0,0-2.9h-.483a.7.7,0,1,1,0-1.4h.483a1.448,1.448,0,1,0-1.448-1.448v2.148a.7.7,0,0,1-1.4,0Z"
            transform="translate(-70.029 -141.945)"
            fill="#fe7456"
            clip-rule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect
            id="Rectangle_860"
            data-name="Rectangle 860"
            width="38.175"
            height="40.551"
            fill="#fe7456"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <rect
            id="Rectangle_859"
            data-name="Rectangle 859"
            width="6.494"
            height="10.85"
            fill="#fe7456"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <path
            id="Path_7416"
            data-name="Path 7416"
            d="M119.7,272.7a.7.7,0,0,0,0,1.4h.482a2.826,2.826,0,0,0,2.846-2.854,2.882,2.882,0,0,0-2.846-2.854H119.7a.7.7,0,0,0,0,1.4h.482a1.451,1.451,0,0,1,0,2.9Z"
            transform="translate(-119.002 -268.398)"
            fill="#ff6645"
            clip-rule="evenodd"
          />
        </clipPath>
      </defs>
      <g id="Group_8587" data-name="Group 8587" transform="translate(0 0)">
        <g id="Group_8606" data-name="Group 8606">
          <g
            id="Group_8590"
            data-name="Group 8590"
            transform="translate(0 7.603)"
          >
            <path
              id="Path_7411"
              data-name="Path 7411"
              d="M70.029,366.516a.7.7,0,1,0,.7-.7.7.7,0,0,0-.7.7"
              transform="translate(-70.029 -365.82)"
              fill="#ff6645"
              fill-rule="evenodd"
            />
            <g
              id="Group_8589"
              data-name="Group 8589"
              clip-path="url(#clip-path)"
            ></g>
          </g>
          <g
            id="Group_8594"
            data-name="Group 8594"
            transform="translate(0 5.94)"
          >
            <circle
              id="Ellipse_705"
              data-name="Ellipse 705"
              cx="0.696"
              cy="0.696"
              r="0.696"
              fill="#ff6645"
            />
            <g
              id="Group_8593"
              data-name="Group 8593"
              clip-path="url(#clip-path-3)"
            >
              <g
                id="Group_8592"
                data-name="Group 8592"
                transform="translate(-16.236 -15.682)"
              >
                <rect
                  id="Rectangle_853"
                  data-name="Rectangle 853"
                  width="38.175"
                  height="40.551"
                  transform="translate(0 0)"
                  fill="#ff6645"
                />
                <g
                  id="Group_8591"
                  data-name="Group 8591"
                  transform="translate(0 0)"
                  clip-path="url(#clip-path-2)"
                >
                  <rect
                    id="Rectangle_854"
                    data-name="Rectangle 854"
                    width="2.183"
                    height="2.183"
                    transform="translate(15.84 15.286)"
                    fill="#ff6645"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_8602" data-name="Group 8602">
            <g id="Group_8601" data-name="Group 8601">
              <path
                id="Path_7413"
                data-name="Path 7413"
                d="M70.029,144.793a2.848,2.848,0,1,1,4.719,2.148,2.848,2.848,0,0,1-1.87,5h-.483a.7.7,0,1,1,0-1.4h.483a1.448,1.448,0,1,0,0-2.9h-.483a.7.7,0,1,1,0-1.4h.483a1.448,1.448,0,1,0-1.448-1.448v2.148a.7.7,0,0,1-1.4,0Z"
                transform="translate(-70.029 -141.945)"
                fill="#fe7456"
                fill-rule="evenodd"
              />
              <g
                id="Group_8600"
                data-name="Group 8600"
                clip-path="url(#clip-path-5)"
              >
                <g
                  id="Group_8599"
                  data-name="Group 8599"
                  transform="translate(-16.236 -9.742)"
                >
                  <rect
                    id="Rectangle_856"
                    data-name="Rectangle 856"
                    width="38.175"
                    height="40.551"
                    transform="translate(0 0)"
                    fill="#fe7456"
                  />
                  <g
                    id="Group_8598"
                    data-name="Group 8598"
                    transform="translate(0 0)"
                    clip-path="url(#clip-path-6)"
                  >
                    <g
                      id="Group_8597"
                      data-name="Group 8597"
                      transform="translate(15.84 9.346)"
                    >
                      <rect
                        id="Rectangle_857"
                        data-name="Rectangle 857"
                        width="6.494"
                        height="10.85"
                        transform="translate(0 0)"
                        fill="#fe7456"
                      />
                      <g
                        id="Group_8596"
                        data-name="Group 8596"
                        transform="translate(0 0)"
                        clip-path="url(#clip-path-7)"
                      >
                        <g
                          id="Group_8595"
                          data-name="Group 8595"
                          transform="translate(0.396 0.396)"
                        >
                          <rect
                            id="Rectangle_858"
                            data-name="Rectangle 858"
                            width="5.702"
                            height="10.058"
                            fill="#fe7456"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group_8605"
            data-name="Group 8605"
            transform="translate(1.663 4.295)"
          >
            <path
              id="Path_7415"
              data-name="Path 7415"
              d="M119.7,272.7a.7.7,0,0,0,0,1.4h.482a2.826,2.826,0,0,0,2.846-2.854,2.882,2.882,0,0,0-2.846-2.854H119.7a.7.7,0,0,0,0,1.4h.482a1.451,1.451,0,0,1,0,2.9Z"
              transform="translate(-119.002 -268.398)"
              fill="#ff6645"
              fill-rule="evenodd"
            />
            <g
              id="Group_8604"
              data-name="Group 8604"
              clip-path="url(#clip-path-8)"
            >
              <g
                id="Group_8603"
                data-name="Group 8603"
                transform="translate(-17.899 -14.098)"
              >
                <rect
                  id="Rectangle_861"
                  data-name="Rectangle 861"
                  width="38.175"
                  height="40.551"
                  transform="translate(0 0)"
                  fill="#ff6645"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_8607"
          data-name="Group 8607"
          transform="translate(8.878 1.356)"
        >
          <path
            id="Path_7417"
            data-name="Path 7417"
            d="M294.217,307.026a3.381,3.381,0,0,0-1.519.342v-2.114a.206.206,0,0,0-.215-.215h-.468a.206.206,0,0,0-.215.215v6.519c0,.1.076.165.2.2a6.19,6.19,0,0,0,1.874.316,2.417,2.417,0,0,0,2.582-2.722A2.253,2.253,0,0,0,294.217,307.026Zm-.3,4.481a2.823,2.823,0,0,1-1.215-.253v-3.026a2.564,2.564,0,0,1,1.291-.418c.937,0,1.57.367,1.57,1.76C295.559,310.913,295.078,311.508,293.913,311.508Z"
            transform="translate(-291.799 -305.039)"
            fill="#ff6645"
          />
          <path
            id="Path_7418"
            data-name="Path 7418"
            d="M378.464,334.869a2.3,2.3,0,0,0-2.494,2.582c0,1.671.8,2.684,2.494,2.684s2.494-1.013,2.494-2.684A2.3,2.3,0,0,0,378.464,334.869Zm0,4.481c-1.443,0-1.6-1.127-1.6-1.886,0-.785.3-1.81,1.6-1.81s1.6,1.025,1.6,1.81C380.059,338.224,379.907,339.35,378.464,339.35Z"
            transform="translate(-370.362 -332.882)"
            fill="#ff6645"
          />
          <path
            id="Path_7419"
            data-name="Path 7419"
            d="M470.957,334.869a7.512,7.512,0,0,0-1.874.253c-.127.038-.2.1-.2.2v4.532a.206.206,0,0,0,.215.215h.468a.206.206,0,0,0,.215-.215v-3.988a3.624,3.624,0,0,1,1.253-.215c1.2,0,1.329.772,1.329,2.114v2.089a.206.206,0,0,0,.215.215h.468a.206.206,0,0,0,.215-.215v-2.57C473.261,335.654,472.653,334.869,470.957,334.869Z"
            transform="translate(-457.083 -332.882)"
            fill="#ff6645"
          />
          <path
            id="Path_7420"
            data-name="Path 7420"
            d="M554.06,335.034a4.55,4.55,0,0,0-1.3-.165c-1.734,0-2.937.873-2.937,2.76,0,1.671.785,2.507,2.089,2.507a2.067,2.067,0,0,0,1.38-.43l.19.266a.208.208,0,0,0,.19.1h.38a.206.206,0,0,0,.215-.215v-4.621C554.262,335.135,554.186,335.071,554.06,335.034Zm-.7,3.772a1.9,1.9,0,0,1-1.177.544c-.734,0-1.468-.228-1.468-1.646,0-1.329.544-2.051,2.646-2.051Z"
            transform="translate(-532.628 -332.882)"
            fill="#ff6645"
          />
          <path
            id="Path_7421"
            data-name="Path 7421"
            d="M634.769,311.963l-.1-.367a.175.175,0,0,0-.177-.152c-.152,0-.291.063-.443.063-1.279,0-1.317-1.063-1.317-1.633v-1.987h1.532a.206.206,0,0,0,.215-.215v-.354a.206.206,0,0,0-.215-.215h-1.532v-1.874a.179.179,0,0,0-.19-.19h-.405a.179.179,0,0,0-.19.19l-.165,1.874h-.81a.206.206,0,0,0-.215.215v.354a.206.206,0,0,0,.215.215h.836v1.658c0,1.671.38,2.785,2.013,2.785.278,0,.949-.013.962-.253A.353.353,0,0,0,634.769,311.963Z"
            transform="translate(-608.173 -305.039)"
            fill="#ff6645"
          />
        </g>
      </g>
    </svg>
  );
};
