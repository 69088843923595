import axios from "axios";

const server = axios.create({
  baseURL: process.env.SERVER_URL,
});

export const init = (shop: string) =>
  server.get("/api/v2/customer/reviews/init", {
    headers: { Bonat_shop: shop },
  });

export const getReviews = (shop: string, page: number, limit: number = 10) =>
  server.get(`/api/v2/customer/reviews/list/${page}?limit=${limit}`, {
    headers: { Bonat_shop: shop },
  });

export const getCustomer = (shop: string, customerId: string) =>
  server.get(`/api/v2/customer/${customerId}`, {
    headers: { Bonat_shop: shop },
  });

export const getPendingReviews = (shop: string, token: string) =>
  server.get(`/api/v2/customer/reviews/checkPendingReviewsRequest`, {
    headers: { Bonat_shop: shop, Authorization: `Bearer ${token}` },
  });

export const witeAReview = (shop: string, token: string, body: any) =>
  server.post(`/api/v2/customer/reviews/writeReview`, body, {
    headers: { Bonat_shop: shop, Authorization: `Bearer ${token}` },
  });
