import { createContext } from "preact";
import { StateUpdater } from "preact/hooks";
import { IConfig, ICouponSettings, ICustomer, IReviewsProps } from "./types";

interface IGlobalState {
  storeEnv: IReviewsProps;
  config: IConfig;
  view: "reviews" | "form" | "success";
  customer: ICustomer;
  couponSettings: ICouponSettings;
  canWriteReview: boolean;
  setView: StateUpdater<"reviews" | "form" | "success">;
  setCoupon: StateUpdater<string>;
  totalPages: number;
  totalNumberOfReviews: number;
  page: number;
  reviews: any[];
  isReviewsLoading: boolean;
  overallRating: number;
  coupon: string;
  pendingRequest: {
    idReviewsRequest: number;
    idVisit: number;
  };
  canLoadMore: boolean;
  fetchMoreReviews: () => Promise<void>;
  revalidate: () => void;
}

export const GlobalState = createContext<IGlobalState>({
  storeEnv: {
    customerEmail: "",
    customerId: "",
    customerMobile: "",
    customerName: "",
    dataWidgetHost: "",
    storeDomain: "",
    storeEmail: "",
    storeId: "",
    storeUsername: "",
    userEmail: "",
    userId: "",
    userPhone: "",
    platform: "",
  },
  config: {
    ecommerceType: "",
    pluginTheme: {
      buttonText: "Write a review",
      isEnabled: false,
      primaryColor: "#ffffff",
      tabIcon: "icon1",
      tabPosition: "right",
      tabText: "Reviews",
    },
    storeUrl: "",
    subscription_status: "",
  },
  view: "reviews",
  customer: {
    idMerchant: 0,
    currency: "",
    referralSystem: true,
    idCustomer: 0,
    customer: {
      name: "",
      email: "",
      phoneNumber: "",
      token: "",
      birthday: null,
      points: 0,
      idCustomer: 0,
      blocked: 0,
    },
    authorization: "",
  },
  couponSettings: {
    discountAmount: 0,
    expiryPeriod: 0,
    idRewardTypes: 0,
    isCouponEnabled: false,
  },
  canWriteReview: false,
  setView: () => {},
  setCoupon: () => {},
  isReviewsLoading: false,
  overallRating: 0,
  page: 1,
  reviews: [],
  totalNumberOfReviews: 0,
  totalPages: 1,
  coupon: "",
  pendingRequest: {
    idReviewsRequest: 0,
    idVisit: 0,
  },
  canLoadMore: false,
  fetchMoreReviews: () => Promise.resolve(),
  revalidate: () => {},
});
