import { h } from "preact";

interface IReviewsIcon2 {
  color?: string;
}

export const ReviewsIcon2 = ({ color = "#ff6645" }: IReviewsIcon2) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_12017"
            data-name="Rectangle 12017"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="rating_1_1" data-name="rating 1 1" clip-path="url(#clip-path)">
        <path
          id="Path_60378"
          data-name="Path 60378"
          d="M8.881,12.242a.586.586,0,1,0,.172.414A.59.59,0,0,0,8.881,12.242Z"
          fill={color}
        />
        <path
          id="Path_60379"
          data-name="Path 60379"
          d="M28.242,0H4.922A1.76,1.76,0,0,0,3.164,1.758v9.726a1.76,1.76,0,0,0,1.758,1.758H5.976a.586.586,0,1,0,0-1.172H4.922a.587.587,0,0,1-.586-.586V1.758a.587.587,0,0,1,.586-.586h23.32a.587.587,0,0,1,.586.586v9.726a.587.587,0,0,1-.586.586H24.323a.586.586,0,0,0-.414.172l-1.929,1.929L20.05,12.242a.586.586,0,0,0-.414-.172H15.066a3.449,3.449,0,0,0-.147-.327,3.335,3.335,0,0,0-2.011-1.677l-.847-.258a.586.586,0,0,0-.721.361l-1.331,3.679a4.434,4.434,0,0,1-2.05,2.387l-1.25.68a1.67,1.67,0,0,0-1.292-.611H1.673A1.675,1.675,0,0,0,0,17.978V28.327A1.675,1.675,0,0,0,1.673,30H5.417a1.672,1.672,0,0,0,1.4-.754l.6.235A7.5,7.5,0,0,0,10.16,30h7.906a2.15,2.15,0,0,0,1.861-3.229,2.15,2.15,0,0,0,1.2-3.155,2.151,2.151,0,0,0-.106-4.091,2.15,2.15,0,0,0-1.866-3.219h-4.7l.657-1.951a3.364,3.364,0,0,0,.177-1.112h4.1l2.172,2.172a.586.586,0,0,0,.829,0l2.172-2.172h3.676A1.76,1.76,0,0,0,30,11.484V1.758A1.76,1.76,0,0,0,28.242,0ZM5.918,28.327a.5.5,0,0,1-.5.5H1.673a.5.5,0,0,1-.5-.5V17.978a.5.5,0,0,1,.5-.5H2.959v7.214a.586.586,0,1,0,1.172,0V17.477H5.417a.5.5,0,0,1,.473.337.578.578,0,0,0,.021.084.5.5,0,0,1,.007.08V28.327Zm13.239-10.85h0a.979.979,0,0,1,0,1.959H15.979a.586.586,0,1,0,0,1.172h4.435a.979.979,0,1,1,0,1.959H15.979a.586.586,0,1,0,0,1.172h3.3a.979.979,0,1,1,0,1.959h-3.3a.586.586,0,1,0,0,1.172h2.087a.979.979,0,0,1,0,1.959H10.16a6.33,6.33,0,0,1-2.32-.439L7.09,28.1V18.043l1.429-.778a5.6,5.6,0,0,0,2.592-3.018l1.142-3.156.315.1a2.189,2.189,0,0,1,1.438,2.793L13.088,16.7a.586.586,0,0,0,.555.773Z"
          fill={color}
        />
        <path
          id="Path_60380"
          data-name="Path 60380"
          d="M27.316,5.682a.93.93,0,0,0-.756-.637L25.524,4.9l-.464-.939a.93.93,0,0,0-.839-.522h0a.93.93,0,0,0-.839.522l-.463.939-1.036.151a.936.936,0,0,0-.518,1.6l.75.731-.177,1.032a.935.935,0,0,0,1.358.986l.927-.488.927.487a.936.936,0,0,0,1.358-.987L26.33,7.373l.75-.731A.931.931,0,0,0,27.316,5.682Zm-1.893.938a.936.936,0,0,0-.269.829l.117.683-.614-.323a.936.936,0,0,0-.871,0l-.614.323.117-.683a.936.936,0,0,0-.269-.829l-.5-.484.686-.1a.935.935,0,0,0,.7-.512l.307-.622.307.622a.936.936,0,0,0,.7.512l.686.1Z"
          fill={color}
        />
        <path
          id="Path_60381"
          data-name="Path 60381"
          d="M19.862,5.682a.93.93,0,0,0-.756-.637L18.07,4.9l-.464-.939a.93.93,0,0,0-.839-.522h0a.93.93,0,0,0-.839.522l-.463.939-1.036.151a.936.936,0,0,0-.518,1.6l.75.731-.177,1.032a.935.935,0,0,0,1.358.986l.927-.488.927.487a.936.936,0,0,0,1.358-.987l-.177-1.032.75-.731A.93.93,0,0,0,19.862,5.682Zm-1.893.938a.936.936,0,0,0-.269.829l.117.683L17.2,7.81a.936.936,0,0,0-.871,0l-.614.323.117-.683a.936.936,0,0,0-.269-.829l-.5-.484.686-.1a.936.936,0,0,0,.7-.512l.307-.622.307.622a.936.936,0,0,0,.7.512l.686.1Z"
          fill={color}
        />
        <path
          id="Path_60382"
          data-name="Path 60382"
          d="M12.409,5.682a.93.93,0,0,0-.756-.637L10.617,4.9l-.464-.939a.93.93,0,0,0-.839-.522h0a.93.93,0,0,0-.839.522L8.011,4.9l-1.036.151a.936.936,0,0,0-.518,1.6l.75.731L7.03,8.406a.935.935,0,0,0,1.358.986l.927-.488.927.487A.936.936,0,0,0,11.6,8.405l-.177-1.032.75-.731A.93.93,0,0,0,12.409,5.682Zm-1.893.938a.936.936,0,0,0-.269.829l.117.683L9.75,7.81a.936.936,0,0,0-.871,0l-.614.323.117-.684a.936.936,0,0,0-.269-.828l-.5-.484.686-.1a.936.936,0,0,0,.7-.512L9.314,4.9l.307.622a.936.936,0,0,0,.7.512l.686.1Z"
          fill={color}
        />
        <path
          id="Path_60383"
          data-name="Path 60383"
          d="M3.545,26.212a.586.586,0,0,0-.586.586v.009a.586.586,0,0,0,1.172,0V26.8A.586.586,0,0,0,3.545,26.212Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
