import { h } from "preact";

export const StarOutlineLarg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.097"
      height="33.097"
      viewBox="0 0 31.632 30.342"
    >
      <path
        id="Path_60424"
        data-name="Path 60424"
        d="M192.665,32.528a2.545,2.545,0,0,1-1.193-.3l-6.829-3.589a.365.365,0,0,0-.344,0l-6.827,3.589a2.561,2.561,0,0,1-3.717-2.7l1.3-7.6a.372.372,0,0,0-.109-.329l-5.52-5.387a2.562,2.562,0,0,1,1.424-4.37l7.629-1.108a.369.369,0,0,0,.164-.067.36.36,0,0,0,.114-.136l3.411-6.918a2.56,2.56,0,0,1,4.593,0l3.413,6.916a.371.371,0,0,0,.278.2l7.635,1.109a2.562,2.562,0,0,1,1.424,4.37L193.989,21.6a.376.376,0,0,0-.1.151.37.37,0,0,0-.014.178l1.3,7.6a2.562,2.562,0,0,1-.566,2.082,2.563,2.563,0,0,1-1.954.914Zm-8.194-6.12a2.545,2.545,0,0,1,1.192.3l6.828,3.588a.35.35,0,0,0,.2.045.391.391,0,0,0,.319-.232.363.363,0,0,0,.02-.2l-1.305-7.6a2.567,2.567,0,0,1,.736-2.267l5.525-5.385a.37.37,0,0,0-.021-.548.369.369,0,0,0-.185-.083l-7.634-1.11a2.559,2.559,0,0,1-1.928-1.4L184.8,4.588a.372.372,0,0,0-.137-.15.369.369,0,0,0-.39,0,.372.372,0,0,0-.137.15l-3.41,6.915a2.56,2.56,0,0,1-1.93,1.4l-7.632,1.112a.369.369,0,0,0-.205.631l5.523,5.385a2.564,2.564,0,0,1,.737,2.267l-1.3,7.6a.37.37,0,0,0,.022.2.371.371,0,0,0,.317.23.371.371,0,0,0,.2-.043l6.826-3.589a2.561,2.561,0,0,1,1.192-.295Z"
        transform="translate(-168.655 -2.186)"
        fill="#ffc107"
      />
    </svg>
  );
};
